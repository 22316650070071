import { combineReducers } from "redux";
import vehiclesReducer from "./vehicleReducer";
import labeledVehiclesReducer from "./labeledVehiclesReducer";
import vehicleListReducer from "./vehicleListReducer";
import settingsReducer from "./settingsReducer";
import faultCodesReducer from "./faultCodesReducer";
import routeReducer from "./routeReducer";
import popupReducer from "./popupReducer";

const rootReducer = combineReducers({
  vehicle: vehiclesReducer,
  labeledVehicles: labeledVehiclesReducer,
  vehicleList: vehicleListReducer,
  settings: settingsReducer,
  faultCodes: faultCodesReducer,
  vehicleRoutes: routeReducer,
  popupView: popupReducer,
});

export default rootReducer;

//export type State = ReturnType<typeof rootReducer>;
